import { DatePicker, Form, ConfigProvider, Select, Space, Button } from 'antd'
import { ExceptionOutlined, DashboardOutlined, DollarOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { formatCurrency, formatNumber } from '../../utils/string-utils'
import dayjs from 'dayjs'
import vi_VN from 'antd/es/locale/vi_VN'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/vi'
import { useCallback, useEffect, useState } from 'react'
import ChartMonth from '../../components/chart/ChartMonth'
import ChartExtendByMonth from '../../components/chart/ChartExtendByMonth'
import { useLoading } from '../../context/LoadingContext'
import {
    apiGetListCompanies,
    apiGetListUsers,
    apiGetRevenueConnection,
    apiGetStatisticalDateConnection,
    apiGetStatisticalDateExpiration,
    apiGetStatisticalTypeConnection,
    apiGetStatisticalTypeExpiration,
} from '../../api'
import defaultParams, {
    REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT,
    STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_FILTER,
    STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
    TRANSACTION_CONNECTION_TYPE_EXTENSION,
} from '../../assets/constants/default_params'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { IUser } from '../../models/user.model'
import { ADMIN_USERNAME } from '../../config/constants'
import ChartDay from '../../components/chart/ChartDay'

const { RangePicker } = DatePicker

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('vi')

export default function DashboardGiaHan() {
    const [totalExpired, setTotalExpired] = useState<any>(0)
    const [totalExtended, setTotalExtended] = useState<any>(0)
    const [totalProfitExtension, setTotalProfitExtension] = useState<number>(0)
    const [totalExpiredRecord, setTotalExpiredRecord] = useState<any>([])
    const [totalExpiredFilter, setTotalExpiredFilter] = useState<any>([])
    const [totalExtendedRecord, setTotalExtendedRecord] = useState<any>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [userOptions, setUserOptions] = useState<{ label: string, value: Number }[]>([])
    const [search, setSearch] = useState<any>({})
    const [defaultSearch, setDefaultSearch] = useState<any>()
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchOptions = useCallback(async () => {
        try {
            const [respCompany, respUser] = await Promise.all([
                apiGetListCompanies({
                    limit: defaultParams.MAX_LIMIT,
                }),
                apiGetListUsers({
                    limit: defaultParams.MAX_LIMIT,
                }),
            ])
            if (respCompany.data?.companies) {
                const compOptions = respCompany.data.companies
                    .map((item: ICompany) => ({
                        label: item.name,
                        value: item.code,
                    }))
                setCompanyOptions(compOptions)
            }
            if (respUser.data?.users) {
                const userOptions = respUser.data.users
                    .filter((item: IUser) => item.username !== ADMIN_USERNAME)
                    .map((item: IUser) => ({
                        label: item.username,
                        value: item.username,
                    }))
                setUserOptions(userOptions)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchStatistics = useCallback(async () => {
        try {
            setIsLoading(true)
            const [respTypeStartDate, respTypeEndDate, respProfit, respExpiredRecord, respExpiredFilter, respExtendedRecord] = await Promise.all([
                apiGetStatisticalTypeConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
                apiGetStatisticalTypeExpiration({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
                apiGetRevenueConnection({
                    amountType: REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT,
                    connectionType: TRANSACTION_CONNECTION_TYPE_EXTENSION,
                    ...search,
                }),
                apiGetStatisticalDateExpiration({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
                apiGetStatisticalDateExpiration({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_FILTER,
                    ...search,
                }),
                apiGetStatisticalDateConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    connectionType: TRANSACTION_CONNECTION_TYPE_EXTENSION,
                    ...search,
                }),
            ])
            if (respTypeEndDate.data != null) {
                setTotalExpired(respTypeEndDate.data?.total)
            } else {
                setTotalExpired(0)
            }
            if (respTypeStartDate.data != null) {
                const totalExtended = respTypeStartDate.data?.types?.find((item: any) => item.type === TRANSACTION_CONNECTION_TYPE_EXTENSION)
                setTotalExtended(totalExtended?.sum || 0)
            } else {
                setTotalExtended({ hetHan: 0, giaHan: 0 })
            }
            if (respProfit.data?.total !== null) {
                setTotalProfitExtension(respProfit.data.total)
            } else {
                setTotalProfitExtension(0)
            }
            if (respExpiredRecord.data?.months != null) {
                setTotalExpiredRecord(respExpiredRecord.data.months)
            } else {
                setTotalExpiredRecord([])
            }
            if (respExpiredFilter.data?.months != null) {
                setTotalExpiredFilter(respExpiredFilter.data.months)
            } else {
                setTotalExpiredFilter([])
            }
            if (respExtendedRecord.data?.months != null) {
                setTotalExtendedRecord(respExtendedRecord.data.months)
            } else {
                setTotalExtendedRecord([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, search])

    useEffect(() => {
        const now = new Date()
        const year = now.getFullYear()
        const defaultParams = {
            cpCode: '',
            startDate: `${year}-01-01`,
            endDate: `${year}-12-31`,
        }
        setDefaultSearch(defaultParams)
        setSearch(defaultParams)
    }, [])

    useEffect(() => {
        fetchOptions()
        if (defaultSearch) {
            fetchStatistics()
        }
    }, [fetchOptions, fetchStatistics, defaultSearch])

    const handleSearch = (values: any) => {
        const rangeDate = values?.dateExpired
        if (rangeDate) {
            const startDate = rangeDate[0] ? rangeDate[0]?.format('YYYY-MM-DD') : null
            const endDate = rangeDate[1] ? rangeDate[1]?.format('YYYY-MM-DD') : null
            values.startDate = startDate
            values.endDate = endDate
        }

        setSearch({
            cpCode: values.cpCode,
            startDate: values.startDate || defaultSearch.startDate,
            endDate: values.endDate || defaultSearch.endDate,
        })
    }

    const handleClearSearch = () => {
        setSearch(defaultSearch)
        formSearch.resetFields()
    }

    return (
        <div>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginBottom: 20,
                }}
                form={formSearch} onFinish={handleSearch} id="searchForm" layout="vertical" autoComplete="off">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                }}>
                    <Form.Item name="cpCode" label={<b>Đối tác</b>}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn đối tác"
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...companyOptions,
                            ]}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    {defaultSearch && (
                        <ConfigProvider locale={vi_VN}>
                            <Form.Item name="dateExpired" label="Ngày hết hạn">
                                <RangePicker
                                    defaultValue={[dayjs(defaultSearch.startDate), dayjs(defaultSearch.endDate)]}
                                    format="YYYY-MM-DD"
                                    picker="date" />
                            </Form.Item>
                        </ConfigProvider>
                    )}
                    {/* <Form.Item name='ownerBy' label='Nhân sự'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn nhân sự"
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...userOptions
                            ]}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item> */}
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 40, marginBottom: 20 }}>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <ExceptionOutlined /> SL sim hết hạn: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatNumber(totalExpired)}</span>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <DashboardOutlined /> Gia hạn: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatNumber(totalExtended)}</span>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <DollarOutlined /> Lợi nhuận từ GH: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatCurrency(totalProfitExtension)}</span>
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                {totalExtendedRecord.length > 0 && totalExpiredRecord.length > 0 && totalExpiredFilter.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                        <ChartExtendByMonth
                            extended={totalExtendedRecord}
                            expired={totalExpiredRecord}
                            filtered={totalExpiredFilter}
                        />
                    </div>
                )}
                {totalExpiredRecord.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                        <ChartMonth
                            data={totalExpiredRecord}
                            title='Số lượng sim cần gia hạn theo tháng'
                            label='SL sim cần GH'
                        />
                    </div>
                )}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                {totalExpiredRecord.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                        <ChartDay
                            data={totalExpiredRecord}
                            title='SL sim hết hạn theo ngày'
                        />
                    </div>
                )}
                {totalExtendedRecord.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                        <ChartMonth
                            data={totalExtendedRecord}
                            title='Số lượng sim gia hạn theo tháng'
                            label='SL sim đã GH'
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
