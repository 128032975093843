import { useCallback, useEffect, useState } from 'react'
import {
    Button, DatePicker, Divider, Form,
    Input, Modal, Radio, Select,
    Space, TableProps, Tabs, Typography
} from 'antd'
import {
    SearchOutlined, UndoOutlined, PlusOutlined, UserOutlined,
    ShopOutlined, ClockCircleOutlined, EditOutlined, LeftCircleOutlined
} from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import {
    apiAddListCallTelesale, apiAddListCustomerResource, apiAddListPhoneAgencies, apiGetListCallInfo,
    apiGetListCallLog, apiGetListCompanies, apiGetListCustomerResource, apiGetListPhoneAgencies,
    apiGetListTransactionConnectionView, apiUpdateListCallInfo, apiUpdateListCustomerResource
} from '../../api'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { ICompany } from '../../models/company.modal'
import { renderDateByUnix, renderType } from './method'
import { formatDate, formatDateTime } from '../../utils/entity-utils'
import { typeCustomerOptions, typeNoteCustomerOptions } from '../../assets/constants/default_options'
import dayjs from 'dayjs'
import { toastSuccess } from '../../utils/toast'
import { authSelector } from '../../redux/slices/authSlice'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../utils/string-utils'
const { Text } = Typography

export const renderResult = (result: string) => {
    switch (result) {
        case 'AGENCY':
            return 'Đại lý'
        case 'NOT_OWNER':
            return 'Không phải chủ xe'
        case 'CALL_LATER':
            return 'Khách gọi lại sau'
        case 'NO_ANSWER':
            return 'Không nghe máy'
        case 'NO_NEED':
            return 'Không có nhu cầu'
        case 'BOUGHT_NEW':
            return 'Đã mua SIM mới'
        case 'OTHER_REASONS':
            return 'Khác'
        default:
            return result
    }
}

export default function Telesale() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [transactionConnection, setTransactionConnection] = useState<any>({})
    const [customers, setCustomers] = useState<any>([])
    const [agencies, setAgencies] = useState<any>([])
    const [callLog, setCallLog] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [tabTransaction, setTabTransaction] = useState<any>({})
    const [search, setSearch] = useState<any>({})
    const [filter, setFilter] = useState<any>('')
    const [detailCustomer, setDetailCustomer] = useState<any>({})
    const [detailTransaction, setDetailTransaction] = useState<any>({})
    const [detailCalls, setDetailCalls] = useState<any>({})
    const [modalCreateCallInfo, setModalCreateCallInfo] = useState<any>({})
    const [modalUpdateCallInfo, setModalUpdateCallInfo] = useState<any>({})
    const [modalUpdateCustomer, setModalUpdateCustomer] = useState(false)
    const [modalCreateAgency, setModalCreateAgency] = useState<any>({})
    const [modalCreateCustomer, setModalCreateCustomer] = useState(false)
    const { setIsLoading } = useLoading()
    const [formSearch] = Form.useForm()
    const [formCreateCallInfo] = Form.useForm()
    const [formUpdateCallInfo] = Form.useForm()
    const [formUpdateCustomer] = Form.useForm()
    const [formCreateCustomer] = Form.useForm()
    const authState = useSelector(authSelector)
    const { account } = authState

    const fetchCustomer = async (tran: any) => {
        const cus = customers.find((cus: any) => cus.cp_code === tran.cpCode
            && (cus.phone_number === tran.phoneCustomer || cus.phone_number === Number(tran.phoneAgency).toString()))
        formUpdateCustomer.setFieldValue('cp_code', cus.cp_code)
        formUpdateCustomer.setFieldValue('phone_number', cus.phone_number)
        formUpdateCustomer.setFieldValue('full_name', cus.full_name)
        formUpdateCustomer.setFieldValue('email', cus.email)
        formUpdateCustomer.setFieldValue('address', cus.address)
        formUpdateCustomer.setFieldValue('nation', cus.nation)
        formUpdateCustomer.setFieldValue('date_of_birth', cus.date_of_birth && dayjs(cus.date_of_birth))
        formUpdateCustomer.setFieldValue('number_identity', cus.number_identity)
        formUpdateCustomer.setFieldValue('type_identity', cus.type_identity)
        formUpdateCustomer.setFieldValue('expire_date', cus.expire_date && dayjs(cus.expire_date))
        formUpdateCustomer.setFieldValue('note', cus.note)
        setDetailCustomer(cus)
    }

    const fetchCall = useCallback(async (pk: string) => {
        try {
            setIsLoading(true)
            const resp = await apiGetListCallInfo({ pk })
            if (resp.status === 200) {
                setDetailCalls(resp.data)
                return resp.data
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading])

    const transactionExpireColumns: TableProps<any>['columns'] = [
        { key: 'id', title: 'STT', render: (_text, _record, index) => index + 1 },
        {
            key: 'phoneSim',
            title: 'Msisdn',
            dataIndex: 'phoneSim',
        },
        {
            key: 'serialSim',
            title: 'Serial Sim',
            dataIndex: 'serialSim',
        },
        {
            key: 'phone',
            title: 'Số điện thoại',
            render: data => Number(data.phoneCustomer || data.phoneAgency),
        },
        {
            key: 'dataPack',
            title: 'Gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'startDate',
            title: 'Ngày đấu nối',
            dataIndex: 'startDate',
            render: startDate => renderDateByUnix(startDate),
        },
        {
            key: 'endDate',
            title: 'Ngày hết hạn',
            dataIndex: 'endDate',
            render: endDate => renderDateByUnix(endDate),
        },
        {
            key: 'lastResult',
            title: 'Thông tin gọi',
            render: data => callLog.find((log: any) => log.call_id === `${data.phoneSim}#${data.serialSim}`)?.result ?
                renderResult(callLog.find((log: any) => log.call_id === `${data.phoneSim}#${data.serialSim}`)?.result) : '',
        },
        {
            key: 'lastDateTime',
            title: 'Thời gian gọi',
            render: data => callLog.find((log: any) => log.call_id === `${data.phoneSim}#${data.serialSim}`)?.updated_at ?
                formatDateTime(callLog.find((log: any) => log.call_id === `${data.phoneSim}#${data.serialSim}`)?.updated_at) : '',
        },
        {
            key: 'action',
            title: 'Tác vụ',
            render: data => renderAction(data)
        }
    ]

    const callInfoColumns: TableProps<any>['columns'] = [
        {
            key: 'owner_by',
            title: 'Người gọi',
            dataIndex: 'owner_by',
            render: owner_by => owner_by || 'system'
        },
        {
            key: 'result',
            title: 'Ghi chú',
            dataIndex: 'result',
            render: result => renderResult(result)
        },
        {
            key: 'sk',
            title: 'Thời gian',
            dataIndex: 'sk',
            render: sk => sk.split('#')[1],
        },
        {
            key: 'result',
            title: 'Tác vụ',
            render: data => (
                <Button onClick={() => {
                    formUpdateCallInfo.setFieldValue('result', data.result)
                    setModalUpdateCallInfo({ isOpen: true, data })
                }} style={{ background: '#d5a720', color: 'white' }}>
                    <EditOutlined />
                </Button>
            )
        },
    ]

    const handleShowDetailCustomer = async ({ called, data, pk, customer }: any) => {
        if (called) {
            const call = await fetchCall(pk)
            setTabTransaction({
                data: renderCustomer({ ...data, pk }, customer, call),
                type: 'CUSTOMER'
            })
        } else {
            setDetailCalls({})
            setTabTransaction({
                data: renderCustomer({ ...data, pk }, customer, {}),
                type: 'CUSTOMER'
            })
        }
        setDetailTransaction({ ...data, pk })
        fetchCustomer(data)
    }

    const handleShowDetailAgency = async ({ called, data, pk }: any) => {
        if (called) {
            const call = await fetchCall(pk)
            setTabTransaction({
                data: renderAgency({ ...data, pk }, call),
                type: 'AGENCY'
            })
        } else {
            setDetailCalls({})
            setTabTransaction({
                data: renderAgency({ ...data, pk }, {}),
                type: 'AGENCY'
            })
        }
        setDetailTransaction({ ...data, pk })
    }

    const handleShowDetailWaiting = async ({ called, data, pk }: any) => {
        if (called) {
            const call = await fetchCall(pk)
            setTabTransaction({
                data: renderWaiting({ ...data, pk }, call),
                type: 'WAITING'
            })
        } else {
            setDetailCalls({})
            setTabTransaction({
                data: renderWaiting({ ...data, pk }, {}),
                type: 'WAITING'
            })
        }
        setDetailTransaction({ ...data, pk })
    }

    const renderAction = (data: any) => {
        const pk = `${data.phoneSim}#${data.serialSim}`
        const called = callLog.find((log: any) => log.call_id === pk)
        const customer = customers.find((cus: any) => cus.phone_number === data.phoneCustomer
            || cus.phone_number === Number(data.phoneAgency).toString())
        if (customer) {
            return (
                <Button onClick={() => handleShowDetailCustomer({ called, data, pk, customer })}
                    style={{ width: 132, background: '#a49c22', color: 'white' }}>
                    Khách hàng <UserOutlined />
                </Button>
            )
        }
        const agency = agencies.find((agen: any) => agen.phoneAgency === `0${data.phoneCustomer}`)
        if (agency) {
            return (
                <Button onClick={() => handleShowDetailAgency({ called, data, pk })}
                    style={{ width: 132, background: '#285a9e', color: 'white' }}>
                    Đại lý <ShopOutlined />
                </Button>
            )
        }
        return (
            <Button onClick={() => handleShowDetailWaiting({ called, data, pk })}
                style={{ width: 132, background: '#289e45', color: 'white' }}>
                Chờ xác nhận <ClockCircleOutlined />
            </Button>
        )
    }

    const renderWaiting = (tran: any, call: any) => {
        return (<>
            <Button onClick={() => setTabTransaction({})}
                icon={<LeftCircleOutlined />}
                style={{ background: 'gray', color: 'white' }}>
                Trở lại
            </Button>
            <h2>Thông tin chờ xác nhận ({tran.phoneCustomer || tran.phoneAgency})</h2>
            {renderDetailTransaction(tran)}
            <Tabs
                size='middle'
                items={[
                    {
                        key: 'call',
                        label: 'Cuộc gọi',
                        children: <div>
                            <Space style={{ marginBottom: 20 }}>
                                <Button onClick={() => setModalCreateCallInfo({ isOpen: true, data: tran })}
                                    style={{ background: '#821dec', color: 'white' }} icon={<PlusOutlined />}>
                                    Cuộc gọi
                                </Button>
                                <Button onClick={() => {
                                    formCreateCustomer.setFieldValue('pk', tran.pk)
                                    formCreateCustomer.setFieldValue('cp_code', tran.cpCode)
                                    formCreateCustomer.setFieldValue('phone_number', tran.phoneCustomer || tran.phoneAgency)
                                    setModalCreateCustomer(true)
                                }}
                                    style={{ background: '#a49c22', color: 'white' }} icon={<PlusOutlined />}>
                                    Khách hàng
                                </Button>
                                <Button onClick={() => setModalCreateAgency({ isOpen: true, data: tran })}
                                    style={{ background: '#285a9e', color: 'white' }} icon={<PlusOutlined />}>
                                    Đại lý
                                </Button>
                            </Space>
                            <CustomTable
                                namePage='cuộc gọi'
                                columns={callInfoColumns}
                                dataSource={call.calls}
                                total={call.total}
                                isHiddenPagination={true}
                            />
                        </div>,
                    },
                ]}
            />
        </>)
    }

    const renderCustomer = (tran: any, cus: any, call: any) => {
        return (<>
            <Button onClick={() => setTabTransaction({})}
                icon={<LeftCircleOutlined />}
                style={{ background: 'gray', color: 'white' }}>
                Trở lại
            </Button>
            <h2>Thông tin khách hàng ({tran.phoneCustomer || tran.phoneAgency})</h2>
            {renderDetailTransaction(tran)}
            <Tabs
                size='middle'
                items={[
                    {
                        key: 'call',
                        label: 'Cuộc gọi',
                        children: <div>
                            <Space style={{ marginBottom: 20 }}>
                                <Button onClick={() => setModalCreateCallInfo({ isOpen: true, data: tran })}
                                    style={{ background: '#821dec', color: 'white' }} icon={<PlusOutlined />}>
                                    Cuộc gọi
                                </Button>
                            </Space>
                            <CustomTable
                                namePage='cuộc gọi'
                                columns={callInfoColumns}
                                dataSource={call.calls}
                                total={call.total}
                                isHiddenPagination={true}
                            />
                        </div>,
                    },
                    {
                        key: 'customer',
                        label: 'Khách hàng',
                        children: <div>
                            <h3 style={{ margin: 0 }}>Chi tiết khách hàng</h3>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                                <div style={{ flex: 1 }}>
                                    <p><Text strong>Mã công ty: </Text>{cus?.cp_code}</p>
                                    <p><Text strong>SĐT Khách hàng: </Text>{cus?.phone_number}</p>
                                    <p><Text strong>Tên khách hàng: </Text>{cus?.full_name}</p>
                                    <p><Text strong>Địa chỉ: </Text>{cus?.address}</p>
                                    <p><Text strong>Số giấy tờ: </Text>{cus?.number_identity}</p>
                                    <p><Text strong>Ngày hết hạn: </Text>{cus?.expire_date && formatDate(cus?.expire_date)}</p>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <p><Text strong>Nhân sự thêm: </Text>{cus?.owner_by}</p>
                                    <p><Text strong>Email: </Text>{cus?.email}</p>
                                    <p><Text strong>Ngày sinh: </Text>{cus?.date_of_birth && formatDate(cus?.date_of_birth)}</p>
                                    <p><Text strong>Quốc gia: </Text>{cus?.nation}</p>
                                    <p><Text strong>Loại giấy tờ: </Text>{cus?.type_identity}</p>
                                    <p><Text strong>Ngày tạo: </Text>{formatDate(cus?.CreatedAt)}</p>
                                </div>
                            </div>
                            <Text strong>Ghi chú: </Text>{cus?.note}
                            <div style={{ marginTop: 20 }}>
                                {account.canUpdateListCustomerResource && (
                                    <Button disabled={!cus?.customer_id} onClick={() => setModalUpdateCustomer(true)} type="primary" icon={<EditOutlined />}>Cập nhật</Button>
                                )}
                            </div>
                        </div>,
                    },
                ]}
            />
        </>)
    }

    const renderAgency = (tran: any, call: any) => {
        return (<>
            <Button onClick={() => setTabTransaction({})}
                icon={<LeftCircleOutlined />}
                style={{ background: 'gray', color: 'white' }}>
                Trở lại
            </Button>
            <h2>Thông tin đại lý ({tran.phoneCustomer || tran.phoneAgency})</h2>
            {renderDetailTransaction(tran)}
            <Tabs
                size='middle'
                items={[
                    {
                        key: 'call',
                        label: 'Cuộc gọi',
                        children: <div>
                            <Space style={{ marginBottom: 20 }}>
                                <Button onClick={() => setModalCreateCallInfo({ isOpen: true, data: tran })}
                                    style={{ background: '#821dec', color: 'white' }} icon={<PlusOutlined />}>
                                    Cuộc gọi
                                </Button>
                            </Space>
                            <CustomTable
                                namePage='cuộc gọi'
                                columns={callInfoColumns}
                                dataSource={call.calls}
                                total={call.total}
                                isHiddenPagination={true}
                            />
                        </div>,
                    },
                    {
                        key: 'agency',
                        label: 'Đại lý',
                        children: <div>
                            <h3 style={{ margin: 0 }}>Chi tiết đại lý</h3>
                            <p><Text strong>Số điện thoại: </Text>{tran.phoneCustomer}</p>
                            <p><Text strong>Mã công ty: </Text>{tran.cpCode}</p>
                        </div>,
                    },
                ]}
            />
        </>)
    }

    const fetchCustomers = useCallback(async (trans: any) => {
        const phoneCustomers = [] as string[]
        trans.map((tran: any) => {
            if (tran.phoneCustomer && !phoneCustomers.includes(tran.phoneCustomer)) {
                phoneCustomers.push(`'${tran.phoneCustomer}'`)
            }
            if (tran.phoneAgency && !phoneCustomers.includes(Number(tran.phoneAgency).toString())) {
                phoneCustomers.push(`'${Number(tran.phoneAgency)}'`)
            }
            return tran
        })
        if (phoneCustomers.length > 0) {
            const respCustomer = await apiGetListCustomerResource({
                page: 1,
                size: phoneCustomers.length,
                condition: `cp_code = '${search.cpCode}' AND phone_number IN (${phoneCustomers.toString()})`
            })
            setCustomers(respCustomer.data.customers)
        }
    }, [search.cpCode])

    const fetchAgencies = useCallback(async (trans: any) => {
        const phoneAgencies = [] as string[]
        trans.map((tran: any) => {
            if (tran.phoneCustomer && !phoneAgencies.includes(tran.phoneCustomer)) {
                phoneAgencies.push(`'0${tran.phoneCustomer}'`)
            }
            return tran
        })
        if (phoneAgencies.length > 0) {
            const respAgency = await apiGetListPhoneAgencies({
                page: 1,
                size: phoneAgencies.length,
                condition: `"cpCode"='${search.cpCode}' AND "phoneAgency" IN (${phoneAgencies.toString()})`
            })
            setAgencies(respAgency.data.phoneAgencies)
        }
    }, [search.cpCode])

    const fetchCallLog = useCallback(async (trans: any) => {
        const callLogs = [] as string[]
        trans.map((tran: any) => {
            callLogs.push(`'${tran.phoneSim}#${tran.serialSim}'`)
            return tran
        })
        if (callLogs.length > 0) {
            const respAgency = await apiGetListCallLog({
                page: 1,
                size: callLogs.length,
                condition: `cp_code = '${search.cpCode}' AND call_id IN (${callLogs.toString()})`
            })
            setCallLog(respAgency.data.calls)
        }
    }, [search.cpCode])

    const fetchTransactionConnection = useCallback(async () => {
        if (!search.cpCode && !search.day) {
            setTransactionConnection({})
            return
        }
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionConnectionView({
                page: currentPage,
                size: pageSize,
                ...search,
            })
            if (resp.status === 200) {
                fetchCustomers(resp.data?.transactions)
                fetchAgencies(resp.data?.transactions)
                fetchCallLog(resp.data?.transactions)
                setTransactionConnection(resp.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, fetchCustomers, fetchAgencies, fetchCallLog, currentPage, pageSize, search])

    const fetchCompanies = useCallback(async () => {
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data != null) {
                const options = resp.data.companies.map((item: ICompany) => ({
                    label: item.name,
                    value: item.code,
                }))
                setCompanyOptions(options)
            } else {
                setCompanyOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchTransactionConnection()
        fetchCompanies()
    }, [fetchTransactionConnection, fetchCompanies])

    const handleSearch = ({ cpCode, day, date }: any) => {
        if (!cpCode && !day) return
        let startDate, endDate
        if (date) {
            startDate = date[0] ? date[0]?.format('YYYY-MM-DD') : null
            endDate = date[1] ? date[1]?.format('YYYY-MM-DD') : null
        }
        setSearch({ cpCode, day, startDate, endDate })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({})
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
        setTabTransaction({})
    }

    const handleAddCallInfo = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiAddListCallTelesale({
                calls: [{
                    pk: modalCreateCallInfo.data?.pk,
                    cp_code: modalCreateCallInfo.data?.cpCode,
                    result: values.result,
                }]
            })
            if (resp.status === 200) {
                toastSuccess('Thêm thông tin cuộc gọi thành công!')
                fetchCallLog(transactionConnection.transactions)
                const call = await fetchCall(modalCreateCallInfo.data?.pk)
                switch (tabTransaction.type) {
                    case 'WAITING':
                        setTabTransaction({
                            data: renderWaiting(detailTransaction, call),
                            type: 'WAITING'
                        })
                        break
                    case 'CUSTOMER':
                        setTabTransaction({
                            data: renderCustomer(detailTransaction, detailCustomer, call),
                            type: 'CUSTOMER'
                        })
                        break
                    case 'AGENCY':
                        setTabTransaction({
                            data: renderAgency(detailTransaction, call),
                            type: 'AGENCY'
                        })
                        break
                    default:
                        break
                }
                setModalCreateCallInfo({})
                formCreateCallInfo.resetFields()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateCallInfo = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiUpdateListCallInfo({
                calls: [{
                    pk: modalUpdateCallInfo.data?.pk,
                    sk: modalUpdateCallInfo.data?.sk,
                    result: values.result,
                }]
            })
            if (resp.status === 200) {
                toastSuccess('Cập nhật thông tin cuộc gọi thành công!')
                fetchCallLog(transactionConnection.transactions)
                const call = await fetchCall(modalUpdateCallInfo.data?.pk)
                switch (tabTransaction.type) {
                    case 'WAITING':
                        setTabTransaction({
                            data: renderWaiting(detailTransaction, call),
                            type: 'WAITING'
                        })
                        break
                    case 'CUSTOMER':
                        setTabTransaction({
                            data: renderCustomer(detailTransaction, detailCustomer, call),
                            type: 'CUSTOMER'
                        })
                        break
                    case 'AGENCY':
                        setTabTransaction({
                            data: renderAgency(detailTransaction, call),
                            type: 'AGENCY'
                        })
                        break
                    default:
                        break
                }
                setModalUpdateCallInfo({})
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateCustomer = async (values: any) => {
        try {
            setIsLoading(true)
            const dataUpdate = {
                ID: detailCustomer.ID,
                customer_id: detailCustomer.customer_id,
                ...values,
            }
            const resp = await apiUpdateListCustomerResource({
                customers: [dataUpdate]
            })
            if (resp.status === 200) {
                toastSuccess('Cập nhật thông tin thành công!')
                const updateCustomer = customers.map(((cus: any) => {
                    if (cus.cp_code === detailCustomer.cp_code && cus.phone_number === detailCustomer.phone_number) {
                        setTabTransaction({
                            data: renderCustomer(detailTransaction, dataUpdate, detailCalls),
                            type: 'CUSTOMER'
                        })
                        setDetailCustomer(dataUpdate)
                    }
                    return cus
                }))
                fetchCustomers(transactionConnection.transactions)
                setCustomers(updateCustomer)
                setModalUpdateCustomer(false)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCreateAgency = async () => {
        try {
            setIsLoading(true)
            const resp = await apiAddListPhoneAgencies({
                phoneAgencies: [{
                    cpCode: modalCreateAgency.data?.cpCode,
                    phoneAgency: modalCreateAgency.data?.phoneCustomer || modalCreateAgency.data?.phoneAgency,
                }]
            })
            if (resp.status === 200) {
                toastSuccess('Thêm đại lý thành công!')
                fetchAgencies(transactionConnection.transactions)
                setModalCreateAgency({})
                setTabTransaction({
                    data: renderAgency(detailTransaction, detailCalls),
                    type: 'AGENCY'
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCreateCustomer = async (values: any) => {
        try {
            setIsLoading(true)
            const resp = await apiAddListCustomerResource({
                customers: [values]
            })
            if (resp.status === 200) {
                toastSuccess('Thêm khách hàng thành công!')
                fetchCustomers(transactionConnection.transactions)
                setModalCreateCustomer(false)
                formCreateCustomer.resetFields()
                setTabTransaction({
                    data: renderCustomer(detailTransaction, values, detailCalls),
                    type: 'CUSTOMER'
                })
                setDetailCustomer(values)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleRenderListTransactions = () => {
        let listTrans = transactionConnection.transactions
        if (listTrans?.length === 0) {
            return []
        }
        switch (filter) {
            case 'Chờ xác nhận':
                listTrans = listTrans?.filter((tran: any) => {
                    return !customers.find((cus: any) => cus.phone_number === tran.phoneCustomer
                        || cus.phone_number === Number(tran.phoneAgency).toString())
                        && !agencies.find((agen: any) => agen.phoneAgency === `0${tran.phoneCustomer}`)
                })
                break;
            case 'Khách hàng':
                listTrans = listTrans?.filter((tran: any) => {
                    return customers.find((cus: any) => cus.phone_number === tran.phoneCustomer
                        || cus.phone_number === Number(tran.phoneAgency).toString())
                })
                break;
            case 'Đại lý':
                listTrans = listTrans?.filter((tran: any) => {
                    return !customers.find((cus: any) => cus.phone_number === tran.phoneCustomer
                        || cus.phone_number === Number(tran.phoneAgency).toString())
                        && agencies.find((agen: any) => agen.phoneAgency === `0${tran.phoneCustomer}`)
                })
                break;
            default:
                break;
        }
        return listTrans
    }

    const renderDetailTransaction = (data: any) => {
        return (<>
            <h3 style={{ margin: 0 }}>Chi tiết giao dịch</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                <div style={{ flex: 1 }}>
                    <p><Text strong>Mã công ty </Text>{data?.cpCode}</p>
                    <p><Text strong>Msisdn: </Text>{data?.phoneSim}</p>
                    <p><Text strong>Gói cước: </Text>{data?.dataPack}</p>
                    <p><Text strong>Hạn sử dụng: </Text>{data?.numberDay}</p>
                    <p><Text strong>SĐT báo KH: </Text>{data?.phoneAgency}</p>
                    <p><Text strong>Ngày đấu nối: </Text>{renderDateByUnix(data?.startDate)}</p>
                </div>
                <div style={{ flex: 1 }}>
                    <p><Text strong>Nhân sự đấu: </Text>{data?.ownerBy}</p>
                    <p><Text strong>SerialSim: </Text>{data?.serialSim}</p>
                    <p><Text strong>Loại: </Text>{renderType(data?.type)}</p>
                    <p><Text strong>Giá bán: </Text>{formatCurrency(data?.price)}</p>
                    <p><Text strong>SĐT Khách hàng: </Text>{data?.phoneCustomer}</p>
                    <p><Text strong>Ngày hết hạn: </Text>{renderDateByUnix(data?.endDate)}</p>
                </div>
            </div>
        </>)
    }

    const renderTabListTrans = () => {
        return (
            <>
                <Form form={formSearch} onFinish={handleSearch} autoComplete='off'>
                    <div style={{ display: 'flex', alignItems: 'start', flexWrap: 'wrap', gap: 20 }}>
                        <Form.Item name='cpCode' label='Công ty'
                            rules={[{ required: true, message: 'Vui lòng chọn công ty' }]}>
                            <Select
                                style={{ minWidth: 170 }}
                                showSearch
                                placeholder="Chọn công ty"
                                options={companyOptions}
                                filterOption={filterOptionByLabel}
                            />
                        </Form.Item>
                        <Form.Item name='day' label='Số ngày còn lại'>
                            <Input />
                        </Form.Item>
                        <Form.Item name="date" label="Ngày hết hạn">
                            <DatePicker.RangePicker
                                format="YYYY-MM-DD"
                                picker="date" />
                        </Form.Item>
                        <Space style={{ marginBottom: 24 }}>
                            <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                            <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                        </Space>
                    </div>
                </Form>
                <Divider />
                <Radio.Group
                    defaultValue={'Tất cả'}
                    onChange={e => setFilter(e.target.value || '')}
                    options={['Tất cả', 'Chờ xác nhận', 'Khách hàng', 'Đại lý']}
                />
                <Divider />
                <CustomTable
                    namePage='giao dịch'
                    columns={transactionExpireColumns}
                    dataSource={handleRenderListTransactions()}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    total={transactionConnection.total}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </>
        )
    }

    return (
        <div>
            {tabTransaction.type ? tabTransaction.data : renderTabListTrans()}
            <Modal
                title='Thêm thông tin cuộc gọi'
                open={modalCreateCallInfo.isOpen}
                onCancel={() => {
                    formCreateCallInfo.resetFields()
                    setModalCreateCallInfo({})
                }}
                footer={[
                    <Button htmlType='submit' form='form-create-call-info' type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                Thêm cuộc gọi tới <b>{modalCreateCallInfo.data?.phoneCustomer || modalCreateCallInfo.data?.phoneAgency}</b> cho giao dịch <b>{modalCreateCallInfo.data?.pk}</b>?
                <Divider />
                <Form form={formCreateCallInfo} onFinish={handleAddCallInfo} name='form-create-call-info' labelCol={{ span: 6 }} labelAlign="left" autoComplete='off'>
                    <Form.Item name='result' label='Ghi chú'
                        rules={[{ required: true, message: 'Vui lòng thêm ghi chú!' }]}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại ghi chú"
                            options={typeNoteCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='Cập nhật thông tin cuộc gọi'
                open={modalUpdateCallInfo.isOpen}
                onCancel={() => {
                    formUpdateCallInfo.resetFields()
                    setModalUpdateCallInfo({})
                }}
                footer={[
                    <Button htmlType='submit' form='form-update-call-info' type="primary">Cập nhật</Button>
                ]}
            >
                <Divider />
                Cập nhật cuộc gọi cho giao dịch <b>{modalUpdateCallInfo.data?.pk}</b>?
                <Divider />
                <Form form={formUpdateCallInfo} onFinish={handleUpdateCallInfo} name='form-update-call-info' labelCol={{ span: 6 }} labelAlign="left" autoComplete='off'>
                    <Form.Item name='result' label='Ghi chú'
                        rules={[{ required: true, message: 'Vui lòng thêm ghi chú!' }]}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại ghi chú"
                            options={typeNoteCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='Cập nhật thông tin khách hàng'
                open={modalUpdateCustomer}
                onCancel={() => setModalUpdateCustomer(false)}
                footer={[
                    <Button htmlType='submit' form='form-update-customer' type="primary">Cập nhật</Button>
                ]}
            >
                <Divider />
                <Form form={formUpdateCustomer} onFinish={handleUpdateCustomer} name='form-update-customer' labelCol={{ span: 6 }} labelAlign="left" autoComplete="off">
                    <Form.Item style={{ display: 'none' }} name='pk' />
                    <Form.Item name='cp_code' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            disabled
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phone_number' label='SĐT Khách hàng'
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name='full_name' label='Tên khách hàng'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email'>
                        <Input type='email' />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='date_of_birth' label='Ngày sinh'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại giấy tờ"
                            options={typeCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='note' label='Ghi chú'>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='Thêm đại lý mới'
                open={modalCreateAgency.isOpen}
                onCancel={() => setModalCreateAgency({})}
                footer={[
                    <Button onClick={handleCreateAgency} type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                Thêm số đại lý <b>{modalCreateAgency.data?.phoneCustomer || modalCreateAgency.data?.phoneAgency}</b> cho đối tác <b>{modalCreateAgency.data?.cpCode}</b>?
            </Modal>
            <Modal
                title='Thêm khách hàng mới'
                open={modalCreateCustomer}
                onCancel={() => setModalCreateCustomer(false)}
                footer={[
                    <Button htmlType='submit' form='form-create-customer' type="primary">Thêm</Button>
                ]}
            >
                <Divider />
                <Form form={formCreateCustomer} onFinish={handleCreateCustomer} name='form-create-customer' labelCol={{ span: 6 }} labelAlign="left" autoComplete='off'>
                    <Form.Item style={{ display: 'none' }} name='pk' />
                    <Form.Item name='cp_code' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            disabled
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn công ty"
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='phone_number' label='SĐT Khách hàng'
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name='full_name' label='Tên khách hàng'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='email' label='Email'>
                        <Input type='email' />
                    </Form.Item>
                    <Form.Item name='address' label='Địa chỉ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nation' label='Quốc gia'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='date_of_birth' label='Ngày sinh'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='number_identity' label='Số giấy tờ'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='type_identity' label='Loại giấy tờ'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn loại giấy tờ"
                            options={typeCustomerOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Form.Item name='expire_date' label='Ngày hết hạn'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name='note' label='Ghi chú'>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
