import { DatePicker, Form, ConfigProvider, Select, Space, Button } from 'antd'
import { SearchOutlined, UndoOutlined, ApiOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import vi_VN from 'antd/es/locale/vi_VN'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/vi'
import { useCallback, useEffect, useState } from 'react'
import ChartMonth from '../../components/chart/ChartMonth'
import { useLoading } from '../../context/LoadingContext'
import {
    apiGetListCompanies,
    apiGetStatisticalDateCalling,
    apiGetStatisticalTypeCalling,
} from '../../api'
import defaultParams from '../../assets/constants/default_params'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import ChartDay from '../../components/chart/ChartDay'
import ChartAmountType from '../../components/chart/ChartAmountType'
import { formatNumber } from '../../utils/string-utils'

const { RangePicker } = DatePicker

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('vi')

export default function DashboardTelesale() {
    const [totalRecord, setTotalRecord] = useState<any>([])
    const [totalType, setTotalType] = useState<any>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [search, setSearch] = useState<any>({})
    const [defaultSearch, setDefaultSearch] = useState<any>()
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchOptions = useCallback(async () => {
        try {
            const [respCompany] = await Promise.all([
                apiGetListCompanies({
                    limit: defaultParams.MAX_LIMIT,
                }),
            ])
            if (respCompany.data?.companies) {
                const compOptions = respCompany.data.companies
                    .map((item: ICompany) => ({
                        label: item.name,
                        value: item.code,
                    }))
                setCompanyOptions(compOptions)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchStatistics = useCallback(async () => {
        try {
            setIsLoading(true)
            const [respRecord, respType] = await Promise.all([
                apiGetStatisticalDateCalling(search),
                apiGetStatisticalTypeCalling(search),
            ])
            if (respRecord.data?.months != null) {
                setTotalRecord(respRecord.data.months)
            } else {
                setTotalRecord([])
            }
            if (respType.data != null) {
                setTotalType(respType.data)
            } else {
                setTotalType([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, search])

    useEffect(() => {
        const now = new Date()
        const year = now.getFullYear()
        const defaultParams = {
            cpCode: '',
            startDate: `${year}-01-01`,
            endDate: `${year}-12-31`,
        }
        setDefaultSearch(defaultParams)
        setSearch(defaultParams)
    }, [])

    useEffect(() => {
        fetchOptions()
        if (defaultSearch) {
            fetchStatistics()
        }
    }, [fetchOptions, fetchStatistics, defaultSearch])

    const handleSearch = (values: any) => {
        const rangeDate = values?.dateExpired
        if (rangeDate) {
            const startDate = rangeDate[0] ? rangeDate[0]?.format('YYYY-MM-DD') : null
            const endDate = rangeDate[1] ? rangeDate[1]?.format('YYYY-MM-DD') : null
            values.startDate = startDate
            values.endDate = endDate
        }

        setSearch({
            cpCode: values.cpCode,
            startDate: values.startDate || defaultSearch.startDate,
            endDate: values.endDate || defaultSearch.endDate,
        })
    }

    const handleClearSearch = () => {
        setSearch(defaultSearch)
        formSearch.resetFields()
    }

    return (
        <div>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginBottom: 20,
                }}
                form={formSearch} onFinish={handleSearch} id="searchForm" layout="vertical" autoComplete="off">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                }}>
                    <Form.Item name="cpCode" label={<b>Đối tác</b>}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn đối tác"
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...companyOptions,
                            ]}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    {defaultSearch && (
                        <ConfigProvider locale={vi_VN}>
                            <Form.Item name="dateExpired" label="Ngày hết hạn">
                                <RangePicker
                                    defaultValue={[dayjs(defaultSearch.startDate), dayjs(defaultSearch.endDate)]}
                                    format="YYYY-MM-DD"
                                    picker="date" />
                            </Form.Item>
                        </ConfigProvider>
                    )}
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 40, marginBottom: 20 }}>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <ApiOutlined /> Số cuộc gọi: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatNumber(totalType.total || 0)}</span>
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                {totalType?.total > 0 && (
                    <div style={{ marginBottom: 40, flex: 1 }}>
                        <ChartAmountType
                            data={totalType}
                            title='Khách hàng phản hồi'
                        />
                    </div>
                )}
                {totalRecord.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                        <ChartMonth
                            data={totalRecord}
                            title='Số lượng cuộc gọi theo tháng'
                            label='SL cuộc gọi'
                        />
                    </div>
                )}
            </div>
            {totalRecord.length > 0 && (
                <div style={{ marginBottom: 40, flex: 2, minWidth: 500 }}>
                    <ChartDay
                        data={totalRecord}
                        title='Số lượng cuộc gọi theo ngày'
                    />
                </div>
            )}
        </div>
    )
}
