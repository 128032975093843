import { Doughnut } from 'react-chartjs-2'
import { formatNumber } from '../../utils/string-utils'
import { renderResult } from '../../pages/kit-sim/telesale'

export default function ChartAmountType({ data, title }: any) {
    const types = data.types?.map((item: any) => {
        const percent = item.sum / data.total * 100
        return {
            type: item.type,
            sum: item.sum,
            hidden: percent < 5,
        }
    }).sort((a: any, b: any) => a.sum - b.sum)

    const labels = types?.map((item: any) => renderResult(item.type))

    const datasets = [
        {
            data: types?.map((item: any) => item.sum),
        },
    ] as any

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => formatNumber(context.raw),
                },
            },
            title: {
                display: true,
                text: title,
                font: {
                    size: 16,
                },
                padding: {
                    bottom: 24,
                },
            },
            datalabels: {
                formatter: (_: any, value: any) => {
                    const sum = types[value.dataIndex].sum
                    const hidden = types[value.dataIndex].hidden
                    return hidden ? '' : formatNumber(sum)
                },
                font: {
                    weight: 'bold',
                    size: 14,
                },
            },
        },
    } as any

    return (
        <Doughnut data={{ labels, datasets }} options={options} />
    )
}
