import { useCallback, useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Form, Input, Modal, Space, TableProps } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams, { FORMAT_DATE } from '../../assets/constants/default_params'
import { toastError } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import FormSearch from '../../components/form'
import { apiCheckTransactionTopupOnResource, apiGetListTransactionTopupsByOrderId, apiCheckTransactionTopupOnAdapter } from '../../api'
import dayjs from 'dayjs'
import moment from 'moment'
import { formatCurrency } from '../../utils/string-utils'
import ModalView from './ModalView'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'
import { renderStatus } from '.'

export default function ManagementTopUpHistory() {
    const [tranTopups, setTranTopups] = useState<any>()
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        orderId: '',
        transactionId: '',
        customer: '',
        date: '',
    })
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalCheckAdapter, setModalCheckAdapter] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [dataCheckAdapter, setDataCheckAdapter] = useState<any>({})

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'orderId',
            title: 'Mã đơn hàng',
            dataIndex: 'orderId',
            render: orderId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {orderId}
                </span>
            ),
        },
        {
            key: 'tranId',
            title: 'Mã giao dịch',
            dataIndex: 'tranId',
            render: tranId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {tranId}
                </span>
            ),
        },
        {
            key: 'customer',
            title: 'Số điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: (price) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            ),
        },
        {
            key: 'updatedAt',
            title: 'Cập nhật',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(updatedAt)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            ),
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
            render: status => renderStatus(status),
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    {account.canCheckTransactionTopupOnAdapter && (
                        <Button
                            onClick={() => setModalCheckAdapter({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            type='primary'
                        >
                            Kiểm tra
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            let resp: any
            if (search.orderId) {
                resp = await apiGetListTransactionTopupsByOrderId({
                    orderId: search.orderId,
                    page: currentPage,
                    size: pageSize,
                    transactionId: search.transactionId,
                })
            } else {
                resp = await apiCheckTransactionTopupOnResource({
                    transactionId: search.transactionId,
                    customer: search.customer,
                    date: search.date,
                })
            }
            if (resp.data != null) {
                setTranTopups(resp.data)
            } else {
                setTranTopups({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ orderId, transactionId, customer, date }: any) => {
        if (orderId || transactionId || customer) {
            setSearch({ orderId, transactionId, customer, date: date?.format(FORMAT_DATE) })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        setTranTopups({})
        formSearch.resetFields()
        setSearch({
            orderId: '',
            transactionId: '',
            customer: '',
            date: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleCheckAdapter = async () => {
        try {
            setIsLoading(true)
            const resp = await apiCheckTransactionTopupOnAdapter({
                transactionId: modalCheckAdapter.data?.tranId,
            })
            if (resp.status === 200) {
                switch (resp.data?.TopupStatus) {
                    case 9:
                        resp.data.Message = 'Thành công'
                        break;
                    case 7:
                        resp.data.Message = 'Thất bại'
                        break;
                    case 99:
                        resp.data.Message = 'Chưa rõ kết quả'
                        break;
                    case 0:
                        resp.data.Message = 'Chưa rõ kết quả'
                        break;
                    default:
                        break;
                }
                setDataCheckAdapter(resp.data)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    const handleCloseModalCheckAdapter = () => {
        setModalCheckAdapter({ data: null, isOpen: false })
        setDataCheckAdapter({})
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='orderId' label='Mã đơn hàng'>
                    <Input />
                </Form.Item>
                <Form.Item name='transactionId' label='Mã giao dịch'>
                    <Input />
                </Form.Item>
                <Form.Item name='customer' label='Số điện thoại'>
                    <Input />
                </Form.Item>
                <Form.Item name='date' label='Thời gian'>
                    <DatePicker
                        style={{ width: 170 }}
                        allowClear={false}
                        format={FORMAT_DATE}
                        defaultValue={dayjs(moment().format(FORMAT_DATE), FORMAT_DATE)}
                    />
                </Form.Item>
            </FormSearch>
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={tranTopups?.trans}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={tranTopups?.total || tranTopups?.trans?.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Kiểm tra giao dịch' open={modalCheckAdapter.isOpen}
                onCancel={handleCloseModalCheckAdapter}
                footer={[
                    <Button onClick={handleCheckAdapter}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalCheckAdapter}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Kiểm tra giao dịch <b>{modalCheckAdapter.data?.tranId}</b></p>
                {dataCheckAdapter?.Message && <b>Kết quả: {dataCheckAdapter.Message}</b>}
                <Divider />
            </Modal>
            <ModalView
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}
